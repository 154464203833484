/* eslint-disable react/display-name */
import { useEffect, useState } from "react";
import React from "react";
import Image from "next/image";
import { Form, Button, Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import styles from "@/styles/Home.module.css";
import {
  set_Selected_languages_symp,
  setFilter,
} from "@/redux/features/healer/healersPageSlice.js";
import { healerServices } from "@/services/healerServices";
import Spinner from "react-bootstrap/Spinner";

const Symptomsfilter = () => {
  const [hasMore, setHasMore] = useState(true);
  const [showMore, setShowMore] = useState(false);
  const [listIndex, setListIndex] = useState(1);
  const dispatch = useDispatch();
  const { filters, selected_languages_symp } =
    useSelector((state) => state.healersPage);
  const [symptoms, setSymptoms] = useState([]);
  const [valueSymptoms, setValueSymptoms] = useState("");
  const controller = new AbortController();

  useEffect(() => {
    if (valueSymptoms != "") {
      setSymptoms([]);
      fetchSymptomsSearch();
    } else if (filters.symptoms.length == 0 && valueSymptoms == "") {
      fetchSymptomsSearch();
    }
    else{
      fetchSymptomsSearch();
    }
    return ()=>{
      controller.abort();
     };
  }, [valueSymptoms, filters.symptoms]);

  const fetchSymptomsSearch = async (scroll = null) => {
    let index;
    if (scroll) {
      index = listIndex + 1;
    } else {
      setSymptoms([]);
      index = 1;
    }

    //api pagination index for infinite scrolling
    setListIndex(index);
    try{
    const res = await healerServices.getSymptomsSearch({
      page: index,
      symptom: valueSymptoms,
      signal: controller.signal,
    });

    if (res.status) {
      setShowMore(true);
      if (res.data.data.length > 0) {
        let refinedData = res.data.data.map((i) => ({
          value: i.id,
          label: i.name,
        }));
        setSymptoms((prevSymptoms) => [...prevSymptoms, ...refinedData]);
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    } else {
      toast.error(res.message, {
        autoClose: 5000,
      });
      setHasMore(false);
    } 
  }catch (error) {
    console.error("Error fetching Symptoms data:", error);
  }
  };

  const toggleSymp = (option) => {
    if (selected_languages_symp.includes(option)) {
      dispatch(
        set_Selected_languages_symp(
          selected_languages_symp.filter((item) => item !== option)
        )
      );
    } else {
      dispatch(
        set_Selected_languages_symp([...selected_languages_symp, option])
      );
    }
  };

  return (
    <Dropdown autoClose="true" className="modalitiesdropdown">
      <Dropdown.Toggle  id="dropdown-custom-components" className={filters.symptoms.length == 0 ? styles["modalitiesdropdown"]: styles["modalitiesdropdown"] +" "+"active"}>
      <i class="ri-heart-add-line"></i>
        Health Issues
        {`${
          selected_languages_symp.length != 0 && filters.symptoms.length > 0
            ? `(${selected_languages_symp.length})`
            : " "
        }`}
        {selected_languages_symp.length != 0 ? (
        <Button
          type="button"
          className={styles["close-btn"] +" "+ "theme-btn clearall-filter modality-reset"}
          onClick={() => {
            dispatch(
              setFilter({
                ...filters,
                symptoms: [],
              })
            );
            dispatch(set_Selected_languages_symp([]));
            setValueSymptoms("");
          }}
        >
          <i className="fa fa-times"></i>
        </Button>
      ) : (
        ""
      )}
      </Dropdown.Toggle>

      <Dropdown.Menu>
      <div className="header-modalityfilter">
            <h4>Filter by health issues</h4>
            {selected_languages_symp.length != 0 ? (
              <Button
                type="button"
                className="theme-btn clearall-filter"
                onClick={() => {
                  dispatch(
                    setFilter({
                      ...filters,
                      symptoms: [],
                    })
                  );
                  dispatch(set_Selected_languages_symp([]));
                }}
              >
                Reset filter
              </Button>
            ) : (
              ""
            )}
          </div>
      <Form.Control
            autoFocus
            placeholder="Search..."
            onChange={(e) => setValueSymptoms(e.target.value)}
            value={valueSymptoms}
          />
      <div className="modalitybody filter-options" id="filter-options">
        <InfiniteScroll
          dataLength={symptoms.length}
          next={() => fetchSymptomsSearch(true)}
          hasMore={hasMore}
          scrollableTarget="filter-options"
          loader={
            showMore == false ? (
              <div className="text-center mt-5">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : (
              ""
            )
          }
          style={{ overflow: "hidden" }}
        >
          {symptoms.map((item, index) => (
            <Button
              key={index}
              onClick={() => toggleSymp(item)}
              active={selected_languages_symp.some(i=> JSON.stringify(i)=== JSON.stringify(item))}
            >
              {item.label}
            </Button>
          ))}
        </InfiniteScroll>
        </div>
        <Dropdown.Item
            className="theme-btn mt-3"
            onClick={() => {
              dispatch(
                setFilter({
                  ...filters,
                  symptoms: selected_languages_symp,
                })
              );
              setValueSymptoms("");
            }}
          >
            Apply
          </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default React.memo(Symptomsfilter);
