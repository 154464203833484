/* eslint-disable react/display-name */
import { useEffect, useState } from "react";
import React from "react";
import Image from "next/image";
import { Form, Button, Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "next/router";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  set_Selected_languages,
  setFilter,
} from "@/redux/features/healer/healersPageSlice.js";
import { healerServices } from "@/services/healerServices";
import Spinner from "react-bootstrap/Spinner";
import styles from "@/styles/Home.module.css";
const Modalityfilter = React.memo(() => {
  const dispatch = useDispatch();
  const router = useRouter();
  const [hasMore, setHasMore] = useState(true);
  const [showMore, setShowMore] = useState(false);
  const [listIndex, setListIndex] = useState(1);
  const { filters, selected_languages } =
    useSelector((state) => state.healersPage);
  const [modalities, setModalities] = useState([]);
  const [valueModality, setValueModality] = useState("");
  const controller = new AbortController();

  useEffect(() => {
    if (valueModality != "") {
      setModalities([]);
      fetchModalitiesSearch();
    } else if (filters.modalities.length == 0 && valueModality == "") {
      fetchModalitiesSearch();
    }else{
      fetchModalitiesSearch();
    }

    return ()=>{
     controller.abort();
    };
  }, [valueModality, filters.modalities]);

  const fetchModalitiesSearch = async (scroll = null) => {
    let index;
    if (scroll) {
      index = listIndex + 1;
    } else {
      setModalities([]);
      index = 1;
    }

    //api pagination index for infinite scrolling
    setListIndex(index);
    try {
      const res = await healerServices.getModalitiesSearch({
        page: index,
        modality: valueModality,
        signal: controller.signal,
      });
  
      if (res.status) {
        setShowMore(true);
        if (res.data.data.length > 0) {
          let refinedData = res.data.data.map((i) => ({
            value: i.id,
            label: i.name,
          }));
          setModalities((prevModalities) => [...prevModalities, ...refinedData]);
          setHasMore(true);
        } else {
          setHasMore(false);
        }
      } else {
        toast.error(res.message, {
          autoClose: 5000,
        });
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching modalities data:", error);
    }

  };

  const toggleLang = (option) => {
    if (selected_languages.includes(option)) {
      dispatch(
        set_Selected_languages(
          selected_languages.filter((item) => item !== option)
        )
      );
    } else {
      dispatch(set_Selected_languages([...selected_languages, option]));
    }
  };

  return (
    <Dropdown autoClose="true" className="modalitiesdropdown home-modalitiesdropdown healer-home-modalities-drop">
      <Dropdown.Toggle id="dropdown-custom-components" className={filters.modalities.length == 0 ? styles["modalitiesdropdown"]: styles["modalitiesdropdown"] +" "+"active"}>
      <i class="ri-file-list-line"></i>
        Modalities
        {`${
          selected_languages.length != 0 && filters.modalities.length > 0
            ? `(${selected_languages.length})`
            : " "
        }`}
        {selected_languages.length != 0 ? (
          <Button
            type="button"
            className={styles["close-btn"] +" "+ "theme-btn clearall-filter modality-reset"}
            onClick={() => {
              dispatch(
                setFilter({
                  ...filters,
                  modalities: [],
                })
              );
              dispatch(set_Selected_languages([]));
              setValueModality("");
            }}
          >
            <i className="fa fa-times"></i>
          </Button>
        ) : (
          ""
        )}
      </Dropdown.Toggle>

      <Dropdown.Menu>
      <div className="header-modalityfilter">
            <h4>Filter by modalities</h4>
            {selected_languages.length != 0 ? (
              <Button
                type="button"
                className="theme-btn clearall-filter"
                onClick={() => {
                  dispatch(
                    setFilter({
                      ...filters,
                      modalities: [],
                    })
                  );
                  dispatch(set_Selected_languages([]));
                }}
              >
                Reset filter
              </Button>
            ) : (
              ""
            )}
          </div>
        <Form.Control
          autoFocus
          placeholder="Search..."
          onChange={(e) => {
            setValueModality(e.target.value);
          }}
          value={valueModality}
        />
        <div className="modalitybody filter-options" id="modalitybody">
          <InfiniteScroll
            dataLength={modalities.length}
            next={() => fetchModalitiesSearch(true)}
            hasMore={hasMore}
            scrollableTarget="modalitybody"
            loader={
              showMore == false ? (
                <div className="text-center mt-5">
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              ) : (
                ""
              )
            }
            style={{ overflow: "hidden" }}
          >
            {modalities.map((item, index) => (
              <Button
                key={index}
                onClick={() => toggleLang(item)}
                active={selected_languages.some(i=> JSON.stringify(i)=== JSON.stringify(item))}
              >
                {item.label}
              </Button>
            ))}
          </InfiniteScroll>
        </div>
        <Dropdown.Item
          className="theme-btn mt-3"
          onClick={() => {
            dispatch(
              setFilter({
                ...filters,
                modalities: selected_languages,
              })
            );
            setValueModality("");
          }}
        >
          Apply
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
});

export default Modalityfilter;
